.faq-container {
  background: var(--port-gore) url("/assets/Background_Stars.svg") no-repeat top
    center;
  padding: 88px 0 0;
}

.faq-headline {
  color: var(--white);
  font-family: var(--LL-Circular);
  font-size: 28px;
  letter-spacing: 0.7px;
  text-align: center;
}

@media (min-width: 768px) {
  .faq-headline {
    font-size: 48px;
  }
}

.faq-subhead {
  color: var(--heliotrope);
  font-size: 28px;
  margin-top: 0;
}

.faq-intro {
  color: var(--manatee);
  font-size: 18px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .faq-intro {
    max-width: 600px;
    padding-right: 40px;
  }
}

.anchor-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.anchor-links a {
  color: var(--white);
  display: block;
  font-family: var(--LL-Circular);
  font-size: 16px;
  letter-spacing: 1.3px;
  padding: 12px 0;
  text-decoration: none;
}

.accordion-header {
  color: var(--heliotrope);
  font-family: var(--LL-Circular);
  font-size: 16px;
}

.accordion-container {
  color: #fff;
  font-family: var(--LL-Circular);
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-container {
  padding: 32px;
}

@media (min-width: 600px) {
  .accordion-container,
  .content-container {
    margin: 0 auto;
    max-width: 1144px;
    padding: 32px 64px;
  }

  .accordion-container {
    padding: 0 0 32px 0;
  }
}

.content-container--grid {
  display: block;
}

@media (min-width: 768px) {
  .content-container--grid {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
  }
}

.accordion-container li:first-child .question-wrapper {
  border-top: none;
}

.question-wrapper {
  border-top: solid 1px var(--affair);
  overflow: hidden;
}

.question-wrapper .question {
  font-size: 24px;
  line-height: 1.5;
  margin: 40px 0;
  padding-right: 46px;
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: color 0.15s ease, text-decoration-color 0.15s ease;
}

@media (min-width: 768px) {
  .question-wrapper .question {
    font-size: 30px;
    padding-right: 70px;
  }
}

@media (min-width: 1024px) {
  .question-wrapper .question {
    padding-right: 24%;
  }
}

.question-wrapper .question:hover,
.question-wrapper .question:focus {
  color: var(--heliotrope);
  text-decoration-color: var(--heliotrope);
}

.question-wrapper .question .faq-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
}

.question-wrapper .answer {
  height: 0px;
  overflow: hidden;
  transition: height 0.25s ease-out;
  padding-right: 48px;
}

@media (min-width: 768px) {
  .answer {
    padding-right: 70px;
  }
}

@media (min-width: 1024px) {
  .answer {
    padding-right: 24%;
  }
}

.answer p {
  color: var(--small-light-on-dark);
  font-size: 16px;
  font-weight: 26px;
  margin: 0;
}

.answer-copy a,
.answer-copy a:visited {
  color: #fff;
}

.answer-copy a:hover {
  color: var(--heliotrope);
}

.question-wrapper.--open .question {
  color: var(--heliotrope);
}

.question-wrapper.--open .faq-arrow {
  transform: scale(1, -1);
}

.question-wrapper.--closed .faq-arrow {
  transform: scale(1);
}
