/* START GLOBAL */
html {
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --port-gore: #291b44;
  --heliotrope: #bd72f2;
  --carnation: #f8656a;
  --victoria: #5c497f;
  --bossanova: #402e60;
  --aqua-spring: #f4fafc;
  --topaz: #5f5573;
  --stratos: #000844;
  --gallery: #ececec;
  --affair: #6c5080;
  --manatee: #8e85a0;
  --discrete-manatee: #aba6b9;
  --mine-shaft: #3f3f3f;
  --alto: #d8d8d8;
  --spun-pearl: #5c546f;
  --small-light-on-dark: #bbabda;
  --white: #ffffff;

  /* Tile Colors */
  --your-pink: #ffc2bd;
  --tumbleweed: #dcab7c;
  --bermuda: #85e0c0;
  --vivid_tangerine: #ff9e7c;
  --ziggurat: #badae4;
  --sandwisp: #f7eaa2;

  --transition-timing: 0.25s;

  /* Font family */
  --LL-Circular: "LL Circular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;

  /* transitions */
  --outline-transition: box-shadow 0.15s ease-in-out;
  --color-transition: color 0.15s ease;
  --background-color-transition: background-color 0.15s ease;
  --border-color-transition: border-color 0.15s ease;
  --opacity-transition: opacity 0.15s ease-in-out;
  --slow-opacity-transition: opacity 0.25s ease-in-out;
  --transform-transition: transform 0.15s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-text {
  display: block;
  height: 0;
  text-indent: 100%;
  white-space: nowrap;
  width: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

nav {
  align-items: center;
  background: var(--port-gore);
  border-bottom: 1px solid var(--affair);
  display: flex;
  font-family: var(--LL-Circular);
  font-weight: 500;
  justify-content: space-between;
  padding: 20px;
}

.nav-link {
  color: #fff;
  position: relative;
  text-decoration: none;
  z-index: 2;
}

.nav-link.--active {
  color: var(--heliotrope);
}

.nav-link.--home {
  height: 48px;
  width: 128px;
  transition: filter 0.15s ease-in-out, transform 0.15s ease-in-out;
}

.nav-link.--home:hover {
  transform: scale(1.1);
}

.nav-link.--home:focus {
  outline: 0;
}

nav .Button.--oval.--unfilled {
  height: auto;
  margin-left: 20px;
  padding: 5px 8px;
  border: 3px solid var(--aqua-spring);
}

@media (min-width: 768px) {
  nav .Button.--oval {
    margin-left: 30px;
    padding: 8px 16px;
  }

  nav .Button.--oval.--unfilled {
    margin-left: 40px;
    padding: 8px 16px;
  }
}

.footer {
  background: var(--port-gore);
  padding: 56px 120px;
  display: flex;
  justify-content: space-between;
}

.footer-links-container {
  display: flex;
  list-style-type: none;
  align-items: flex-end;
  margin: 0;
  padding: 0;
}

.footer-item {
  margin-left: 24px;
}

.footer-link {
  color: var(--discrete-manatee);
  text-decoration: none;
  font-family: var(--LL-Circular);
  font-size: 14px;
}

.footer-link:hover {
  color: var(--heliotrope);
  text-decoration: underline;
}

.footer .footer_badge_container {
  align-items: flex-end;
  color: #fff;
  font-size: 12px;
  text-align: left;
  width: 230px;
  display: flex;
  opacity: 0.7;
}

.footer .footer_badge_container .footer_badge {
  font-family: var(--LL-Circular);
  max-width: 156px;
  width: 50%;
}

.footer .footer_badge_container .footer_badge a {
  display: block;
}

.footer .footer_badge_container .footer_badge:first-child {
  border-right: solid 1px #fff;
  padding-right: 20px;
}

.footer .footer_badge_container .footer_badge:last-child {
  padding-left: 20px;
}

.footer .footer_badge .badge {
  max-width: 100%;
}

.footer .footer_badge .badge.--instrument {
  margin-top: 16px;
  max-height: 16px;
}

.footer .footer_badge .badge.--google {
  margin-bottom: -6px;
  max-height: 24px;
  margin-top: 14px;
}

@media (min-width: 600px) {
  .footer .footer_badge_container {
    width: 280px;
  }

  .footer .footer_badge .badge.--instrument {
    max-height: 20px;
  }

  .footer .footer_badge .badge.--google {
    max-height: 28px;
  }
}

@media (max-width: 767px) {
  .footer {
    flex-direction: column;
    padding: 36px 24px;
  }

  .footer-links-container {
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 24px;
    order: 1;
  }

  .footer_badge_container {
    order: 2;
  }

  .footer-link {
    display: block;
    margin-bottom: 24px;
    margin-left: 0;
    font-size: 14px;
  }

  .footer-item {
    margin: 0 24px 0 0;
  }
}

.heading-video-wrapper {
  height: 0;
  left: 0;
  padding-top: 9.375%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.heading-video {
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.panel {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: var(--LL-Circular);
  font-weight: 500;
  text-align: center;
}

.small-copy {
  color: var(--topaz);
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  font-weight: 350;
}

.medium-copy {
  font-size: 28px;
  line-height: 38px;
  margin: 0;
}

.large-copy {
  font-size: 42px;
  line-height: 56px;
  margin: 0;
}

.reversed-small-copy {
  color: var(--small-light-on-dark);
}

@media (min-width: 600px) {
  .small-copy {
    font-size: 18px;
    line-height: 26px;
  }

  .medium-copy {
    font-size: 30px;
    line-height: 38px;
    margin: 0;
  }

  .large-copy {
    font-size: 48px;
    line-height: 64px;
  }
}

/* END GLOBAL */

/* START HERO PANEL */
.--hero {
  background: var(--port-gore);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 670px;
  height: calc(90vh - 80px);
  padding: 0 50px 120px;
  box-sizing: border-box;
}

.--hero .hero_wrapper {
  margin: 0 auto;
  max-width: 492px;
  position: relative;
  width: 100%;
}

@media (max-width: 600px) {
  .--hero {
    padding: 0 24px 120px;
  }
}

@media (min-width: 1024px) {
  .--hero .hero_wrapper {
    max-width: 768px;
  }
}

.hero_copy_container {
  position: relative;
  margin-top: 28px;
}

@media (min-width: 768px) {
  .hero_copy_container {
    margin-top: 70px;
  }
}

@media (min-width: 1024px) {
  .hero_copy_container {
    margin-top: 28px;
  }
}

.--hero .hero_logo {
  width: 100%;
}

@media (min-width: 768px) {
  .--hero .hero_logo {
    margin-left: auto;
  }
}

.--hero .landing-video {
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.--hero .hero_headline {
  color: var(--heliotrope);
  font-weight: 900;
  letter-spacing: 0.5px;
}

.--hero .hero_copy {
  margin: 10px auto 0;
  width: 100%;
  font-weight: 350;
  max-width: 492px;
}

.--hero .hero_badge_container {
  align-items: flex-end;
  bottom: 24px;
  color: #fff;
  font-size: 12px;
  left: 24px;
  position: absolute;
  text-align: left;
  width: 230px;
  display: flex;
  opacity: 0.7;
}

.--hero .hero_badge_container .hero_badge {
  width: 50%;
}

.--hero .hero_badge_container .hero_badge:first-child {
  border-right: solid 1px #fff;
  padding-right: 20px;
}

.--hero .hero_badge_container .hero_badge:last-child {
  padding-left: 20px;
}

.--hero .hero_badge .badge {
  max-width: 100%;
  width: auto;
}

.--hero .hero_badge .badge.--instrument {
  margin-top: 16px;
  height: 16px;
}

.--hero .hero_badge .badge.--google {
  margin-bottom: -6px;
  height: 24px;
  margin-top: 14px;
}

@media (min-width: 600px) {
  .--hero .hero_badge_container {
    bottom: 40px;
    left: 40px;
    width: 280px;
  }

  .--hero .hero_badge .badge.--instrument {
    height: 20px;
  }

  .--hero .hero_badge .badge.--google {
    height: 28px;
  }
}

.--hero .Button {
  height: unset;
  margin: 40px auto 0;
  padding: 16px 48px;
  font-size: 16px;
}

.--hero .hero_particles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.8;
}

.--hero .hero_down_arrow {
  margin-top: 36px;
  text-align: center;
}

.--hero .hero_down_arrow svg {
  height: 36px;
  width: 36px;
}

@media (min-width: 600px) {
  .--hero .hero_down_arrow {
    bottom: 25px;
    left: 50%;
    margin-top: 0;
    position: absolute;
    transform: translateX(-50%);
  }

  .--hero .hero_down_arrow svg {
    height: 48px;
    width: 48px;
  }
}

/* END HERO PANEL */

/* START SETUP PANEL */
.--setup {
  font-family: var(--LL-Circular);
  font-weight: 500;
  padding: 80px 0;
}

@media (min-width: 1024px) {
  .--setup {
    padding: 134px 0;
  }
}

.--setup .setup__steps {
  list-style: none;
  margin: 0 0 60px;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .--setup .setup__steps {
    flex-direction: row;
    justify-content: center;
  }
}

.--setup .large-copy {
  color: var(--stratos);
}

.--setup .setup__step {
  margin: 60px 12px 0;
}

@media (min-width: 1024px) {
  .--setup .setup__step {
    margin: 60px 32px 0;
  }
}

.--setup .setup__step-image {
  display: inline-block;
  width: 148px;
}

.--setup .setup__step-heading {
  color: var(--heliotrope);
  font-size: 28px;
  font-weight: 900;
  line-height: 32px;
  margin: 0;
}

.--setup .setup__step-copy {
  color: var(--topaz);
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  margin: 14px auto 20px;
  padding: 0 24px;
  max-width: 300px;
}

@media (min-width: 600px) {
  .--setup .setup__step-copy {
    padding: 0;
  }
}

.--setup .setup__step-link {
  border-bottom: 1px solid var(--spun-pearl);
  color: var(--spun-pearl);
  display: inline-block;
  font-size: 18px;
  margin-top: 8px;
  padding: 0 3px 8px;
  text-decoration: none;
}

/* END SETUP PANEL */

/* START HOW TO PANEL */
.--how-to {
  margin: 0 0 290px;
  padding: 72px 42px 64px;
  position: relative;
  background: url("/assets/Background_Stars.svg") 50% 12px no-repeat;
  background-color: var(--port-gore);
  background-size: contain;
}

@media (min-width: 768px) {
  .--how-to {
    background: url("/assets/Background_Stars.svg") 50% 12px no-repeat;
    background-color: var(--port-gore);
    margin: 0 0 256px;
    padding: 144px 0 240px;
  }
}

.--how-to .how-to_particles {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.--how-to .how-to_copy {
  line-height: 48px;
}

.--how-to .how-to_headline {
  color: #fff;
  margin: 8px 0 0;
  background: url("/assets/talking_head.svg") no-repeat;
  background-position: 100% 100%;
  background-size: 194px;
  padding-bottom: 147px;
  max-width: 492px;
}

.--how-to .google-home-image {
  bottom: 0;
  height: auto;
  position: absolute;
  transform: translateY(70%) translateX(4%);
  max-width: 220px;
}

@media (min-width: 480px) {
  .--how-to .google-home-image {
    max-width: 331px;
  }
}

@media (min-width: 768px) {
  .--how-to .google-home-image {
    max-width: 398px;
  }
}

/* END HOW TO PANEL */

/* START INSPO PANEL */
.--inspo {
  text-align: center;
  padding: 0 12px 100px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .--inspo {
    max-width: 1168px;
  }
}

.--inspo .inspo_headline {
  color: var(--stratos);
  padding: 0 38px;
}

.--inspo .inspo_copy {
  line-height: 28px;
  margin: 40px auto 0;
  padding: 0 38px;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .--inspo .inspo_copy {
    max-width: 492px;
  }
}

@media (min-width: 1024px) {
  .--inspo .inspo_copy {
    max-width: 768px;
  }
}

.--inspo .placeholder-video {
  margin: 32px 0 136px;
  max-width: 1168px;
  width: 100%;
}

.--inspo .youtube-wrapper {
  height: 0;
  margin-top: 56px;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.--inspo .youtube-embed {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* END INSPO PANEL */

.Privacy,
.TermsOfService {
  font-family: var(--LL-Circular);
  margin: 0 auto;
  max-width: 1144px;
  padding: 32px 64px;
  color: var(--port-gore);
  font-size: 16px;
}
